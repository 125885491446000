var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"card auth-card",on:{"submit":function($event){$event.preventDefault();return _vm.submitHandler($event)}}},[_c('div',{staticClass:"card-content"},[_c('span',{staticClass:"card-title"},[_vm._v(_vm._s(_vm._f("localizeFilter")("HomeBookkeeping")))]),_c('div',{staticClass:"input-field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],class:{
          invalid:
            (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
            (_vm.$v.email.$dirty && !_vm.$v.email.email)
        },attrs:{"id":"email","type":"text"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),(_vm.$v.email.$dirty && !_vm.$v.email.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("EnterEmail"))+" ")]):(_vm.$v.email.$dirty && !_vm.$v.email.email)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("CorrectEmail"))+" ")]):_vm._e()]),_c('div',{staticClass:"input-field"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],class:{
          invalid:
            (_vm.$v.password.$dirty && !_vm.$v.password.required) ||
            (_vm.$v.password.$dirty && !_vm.$v.password.minLength)
        },attrs:{"id":"password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm._f("localizeFilter")("Password")))]),(_vm.$v.password.$dirty && !_vm.$v.password.required)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(_vm._s(_vm._f("localizeFilter")("EnterPassword")))]):_vm._e(),(_vm.$v.password.$dirty && !_vm.$v.password.minLength)?_c('small',{staticClass:"helper-text invalid"},[_vm._v(_vm._s(_vm._f("localizeFilter")("PasswordLimitMore"))+" "+_vm._s(_vm.$v.password.$params.minLength.min))]):_vm._e()])]),_c('div',{staticClass:"card-action"},[_c('div',[_c('button',{staticClass:"btn waves-effect waves-light auth-submit",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("Enter"))+" "),_c('i',{staticClass:"material-icons right"},[_vm._v("send")])])]),_c('p',{staticClass:"center"},[_vm._v(" "+_vm._s(_vm._f("localizeFilter")("HaveAccount"))+" "),_c('router-link',{attrs:{"to":"/register"}},[_vm._v(_vm._s(_vm._f("localizeFilter")("Registration")))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }